<template lang="pug">
div.right_col
  div.info_wrapper
    div.howitworks
      div.bold How it works
      ul
        li Create your project by adding who will receive the video and when you want to send the video
        li Add a prompt you want everyone to record
        li Record your video
        li Record an intro video letting people know about your project
        li Invite people to join in your group video.
        li We’ll send your friends an email with a link to record their video.
        li Time to relax! We’ve got it from here. We’ll remind people and email you when there’s a new video
        li When the day to send the video comes, we will email everyone the video at 5pm EST

</template>

<script>

export default {
  name: 'HowItWorks',
  props: {

  },
  components: {
  },
  computed: {

  },
  data () {
    return {

    }
  },
  apollo: {
  },
  methods: {

  },
  mounted: function () {

  }
}
</script>

<style scoped>
.right_col {
  position: fixed;
  z-index: 100;
  background-color: #4800CD;
  top: 0px;
  left: 70%;
  height: 100%;
  width: 30%;
  overflow-y: auto;
  overflow-x: hidden;
}

.small .right_col {
  position: relative;
  width: 100%;
  height: initial;
  left: 0px;
}

.info_wrapper {
  padding: 30px;
}

.info_wrapper .title {
  margin: 18px 0 18px 0;
  font-family: gibsonsemibold;
  font-size: 32px;
  color: #ffffff;
}

.info_wrapper .birthdate {
  font-size: 18px;
  color: #ffffff;
  margin: 18px 0 8px 0;
}
.calendar_label {
  width: 60px;
  text-align: center;
  font-family: gibsonsemibold;
  font-size: 14px;
  color: #290174;
  margin-bottom: 4px;
  /* padding: 0px 16px; */
  overflow: visible;
  white-space: nowrap;
}
.calendar_birthdate {
  color: #ffffff;
  width: 60px;
  background: #3D00AE;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  font-family: gibsonsemibold;
  font-size: 16px;
  text-align: center;
  padding: 18px 16px;
  margin: 0 0px 14px 0;
}

.small .info_wrapper {
  margin: 40px 20px;
}

.day {
  margin-top: 4px;
  font-size: 24px;
}

.howitworks {
  margin-top: 24px;
  font-size: 18px;
  color: #d6ccffd8;
  font-family: gibsonregular;
  word-wrap: break-word;
}

.howitworks .bold {
  font-family: gibsonsemibold;
}

ul {
  list-style: circle outside;
  padding: 20px;
}

ul li {
  margin-bottom: 22px;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-top: 20px;
}

ol li {
  counter-increment: my-awesome-counter;
  margin-bottom: 12px;
}

ol li::before {
  content: counter(my-awesome-counter) ". ";
}

</style>
